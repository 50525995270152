import { computed, watch } from 'vue'
import xstore from '@/store'
import { RECEIPTS_STORE_ACTIONS, RECEIPTS_STORE_MUTATIONS, RECEIPTS_STORE_GETTERS } from '@/enums'
import { useContext } from '@/composables'
import { Receipt } from '@/models'

export default function useReceiptSelect() {
  const { root } = useContext()

  const companyId = computed(() => root?.$route.params.companyId)

  const selectedReceipts = computed<number[]>(() => {
    return xstore.getters[RECEIPTS_STORE_GETTERS.SELECTED_RECEIPTS]
  })

  const receiptBrowsingList = computed<(string | number)[]>(() => {
    return xstore.getters[RECEIPTS_STORE_GETTERS.RECEIPT_BROWSING_LIST]
  })

  const setSelectedReceipt = (receiptId: number, selected: Boolean) => {
    // TODO: Fix types
    // @ts-ignore
    return xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_SELECTED_RECEIPTS, { receiptIds: [receiptId], selected })
  }

  const isReceiptSelected = (receiptId: number): Boolean => {
    return selectedReceipts.value && selectedReceipts.value.includes(receiptId)
  }

  const setMultipleSelectedReceipts = (receipts: Array<Receipt>, selected: Boolean) => {
    clearSelectedReceipts()
    const receiptIds = receipts.map((receipt) => receipt.id)
    // TODO: Fix types
    // @ts-ignore
    return xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_SELECTED_RECEIPTS, { receiptIds, selected })
  }

  const clearSelectedReceipts = () => {
    // @ts-ignore
    return xstore.commit(RECEIPTS_STORE_MUTATIONS.CLEAR_SELECTED_RECEIPTS)
  }

  const setReceiptBrowsingList = (receiptIds: Array<string | number>) => {
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.UPDATE_RECEIPT_BROWSING_LIST, { receiptIds })
  }

  watch(
    () => companyId.value,
    () => {
      if (selectedReceipts.value?.length) {
        clearSelectedReceipts()
      }

      if (receiptBrowsingList.value.length) {
        setReceiptBrowsingList([])
      }
    }
  )

  return {
    receiptBrowsingList,
    selectedReceipts,
    clearSelectedReceipts,
    isReceiptSelected,
    setMultipleSelectedReceipts,
    setReceiptBrowsingList,
    setSelectedReceipt
  }
}
